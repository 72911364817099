<template>
  <header>
    <router-link class="logo" to="/"><img src="./assets/img/try5.png"></router-link>
    <!-- 电脑导航栏 -->
    <nav>
      <router-link class="navSelect" to="/">首页</router-link> 
      <router-link class="navSelect" to="/projects">项目</router-link>
      <router-link class="navSelect" to="/groups">分组</router-link>
      <router-link class="navSelect" to="/about">关于</router-link>
      <router-link class="navSelect" to="/SORAVERSE">魂月商店</router-link>
      <router-link class="navSelect" to="/404">鸢尾巷叙</router-link>
    </nav>

    <!-- 手机导航栏 -->
    <div class="phone" @click="showList" id="phoneOpen">
      <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"><polygon points="10,22 50,22.000000000000004 90,22 90,30 50,30.000000000000004 10,30" fill="rgba(0,0,0,1)"></polygon><polygon points="10.000000000000004,46 50,46 90,46 90,54 50,54 10.000000000000004,54" fill="rgba(0,0,0,1)"></polygon><polygon points="10,70 50,70 90,70 90,78 50,78 10,78" fill="rgba(0,0,0,1)"></polygon></svg>
    </div>
    <div class="phoneOff" @click="offList" id="phoneOff"><img src="./assets/img/icon/x.webp"></div>
    <div id="phoneList" class="phoneList">
      <div class="inform" @click="offList">
        <section class="part1">
          <h4>页面</h4>
          <div class="list">
            <router-link class="navSelect" to="/">首页</router-link> 
            <router-link class="navSelect" to="/projects">项目</router-link>
            <router-link class="navSelect" to="/groups">分组</router-link>
            <router-link class="navSelect" to="/about">关于</router-link>
          </div>
        </section>
        <section class="part2">
          <h4>接入</h4>
          <div class="list">
            <router-link class="navSelect" to="/SORAVERSE">魂月商店</router-link>
            <router-link class="navSelect" to="/404">鸢尾巷叙</router-link>
          </div>
        </section>
      </div>
    </div>
  </header>


  <main><router-view/></main>


  <footer>
    <div class="left">
      <span>©XQU 2024</span>
      <span>Built by Linglryoyi</span>
      <span>Imitated <a href="https://www.himiku.com/">himiku</a></span>
    </div>
    <div class="right">
      <a class="footerNav" href="http://old.weigucons.cn" target="_blank">返回旧版网站[2023]</a>
      <span><router-link to="/op" class="footerNav">控制台</router-link></span>
    </div>
  </footer>
</template>

<style>
/* 字体 */
@font-face{

  /* 段落展示字体 Home推荐文本描述 */
  font-family:DianZiDianYuan45;
  src:url("./assets/font/DianZiDianYuan45.ttf");
}

/* 横幅标题字体 */
@font-face {
  font-family: SourceHanSansCN;
  src: url(./assets/font/SourceHanSansCN-Regular.ttf);
}

/* 横幅副标题字体 */
@font-face {
  font-family:SourceHanSerifCN-Light ;
  src: url(./assets/font/SourceHanSerifCN-Light.ttf);
}

/* 导航栏字体 */
@font-face {
  font-family: LanTingHeiJian;
  src: url(./assets/font/兰亭黑\ GBK.TTF);
}

/* 小标题字体 */
@font-face {
  font-family: SourceHanSansCN-Bold;
  src: url(./assets/font/SourceHanSansCN-Bold.ttf);
}

/* 底栏字体 Home页推荐标题字体*/
@font-face {
  font-family: LanTingXiHei;
  src: url(./assets/font/方正兰亭细黑_GBK.TTF);
} 

/* 字体补丁 */
h2 {
  font-family: SourceHanSansCN-Bold !important;
  /* 去除了兰亭粗黑简字体，改用此字体。 */
}

p {
  font-family: DianZiDianYuan45 !important;
}

/* 动效 */
@keyframes float-down{
  0% {
    opacity: 0;
    transform: translateY(-18px);
  }
}

@keyframes fadeIn{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
header {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;

  background-color: white;
  height: 90px;
  width: 100vw;
  padding-bottom: 15px;

  align-items: center;
  justify-content: center;
  flex-direction: column;

  z-index: 2;

  transition: .35s cubic-bezier(.25,.46,.45,.94) all;
}

.logo img{
  height: 35px;
  padding-top: 1.5vh;
}

nav {
  padding-top: 0.7vh;
}

nav .navSelect {
  padding: 0 3px 0 3px;
  margin: 0 17px;
  
  color: black;
  text-decoration: none;
  font-family: LanTingHeiJian;
}

nav .navSelect:hover {
  color: #544bdb;
}

main {
  margin-top: 102px; /* 防止标题栏遮挡 */
}

/* 手机导航栏打开按钮 */
.phone {
  display: none;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 7px;
  left: 15px;
  padding: 5px;
}

.phoneOff {
  display: none;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 10px;
  left: 15px;
  padding: 5px;
}

.phoneOff img{
  height: 80%;
}

/* 构建手机导航栏基底 */
.phoneList {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  
  display: none;
  opacity: 0;
  transition: .3s cubic-bezier(.25,.46,.45,.94) all;
  
  background: rgb(255 255 255 / 95%);
}

/* 排序导航栏板块 限制导航栏显示范围*/
.phoneList .inform{
  position: relative;

  margin-top: 45px;
  width: 100%;
  /* height: 100%; */
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

/* 导航栏板块内部排序 */
.phoneList .inform section{
  display: flex;
  padding-top: 15px;
  justify-content: center;
  flex-direction: column  ;
  align-items: center;
  width: 80%;
}

/* 导航栏板块内部标题样式 */
.phoneList .inform section h4{
  font-weight: 600;
  width: 100%;
  text-align: center;
  font-size: 17px;
}

/* 导航栏板块内部选项块样式 */
.phoneList .inform section .list {
  flex-direction: row;
  flex-flow: row wrap;
  display: flex;
  width: 100%;
}

/* 导航栏板块内部选项块内部每个选项的样式 */
.phoneList .inform section .list .navSelect{
  width: 50%;
  text-align: center;
  line-height: 3;
  text-decoration: none;

  color: #1d1d1d;
  font-size: 17px;
}

/* footer */
footer {
  display: flex;
  justify-content: center;
  max-width: 100%;
  
  min-height: 170px;
  overflow: auto;

  width: 100vw;

  color: #ddd;
  line-height: 1.75;


  background-color: #000;

}

footer .left {
  padding:40px 140px;
  font-family: LanTingXiHei;
  display: flex;
  flex-direction: column;
}

footer a{
  color: white;
}

footer .right {
  padding:40px 140px;;
  font-family: LanTingXiHei;
  display: flex;
  flex-direction: column;
}

.footerNav {
  text-decoration: none;
  color: #ddd;
}



/* 自适应 */
@media (max-width: 992px) {
  nav {
    display: none;
  }
  header {
    height: 30px;
  }
  main {
    margin-top: 30px;
  }
  .logo {
    padding-top: 10px;
  }

  .logo img{
    height: 27px;
  }

  .phone {
    display: flex;
  }
  
  footer {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  footer .left {
    padding: 40px 0 0 0;
  }

  footer .right {
    padding: 15px 0 40px 0;
  }

}
</style>

<script>
import { RouterLink } from 'vue-router';

export default{
    methods: {
        showList() {
            // 展开列表
            const css = document.getElementById("phoneList");
            css.style.display = "flex";
            css.style.animation = "fadeIn 0.15s ease-out 0s 1 both";
            // 隐藏展开按钮
            const css2 = document.getElementById("phoneOpen");
            css2.style.display = "none";
            // 显示收起按钮
            const css3 = document.getElementById("phoneOff");
            css3.style.display = "flex";
            css.style.animation = "fadeIn 0.3s ease-out 0s 1 both";
        },
        offList() {
            // 收起列表
            const css = document.getElementById("phoneList");
            css.style = "none";
            //显示展开按钮
            const css2 = document.getElementById("phoneOpen");
            css2.style.display = "flex";
            css2.style.animation = "fadeIn 0.15s ease-out 0s 1 both";
            // 隐藏关闭按钮
            const css3 = document.getElementById("phoneOff");
            css3.style.display = "none";
        },


    },
    components: { RouterLink }
}
</script>