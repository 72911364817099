import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    meta:{ title: "汐阡联集"},
    component: () => import('../views/Home.vue')
  },
  {
    path:'/404',
    meta:{title:'404 - 汐阡联集'},
    component: () => import('../views/404View.vue') 
  },
  {
    path: '/projects',
    meta:{title:'项目 - 汐阡联集'},
    component: () => import('../views/projects.vue'),
      
  },{path:'/projects/battleeve', name:'battleeve', component:() => import('../views/projects/BattleEve.vue')},
  {
    path:'/groups',
    meta:{title:'分组 - 汐阡联集'},
    component: () => import('../views/groups.vue')
  },
  {
    path:'/about',
    meta:{title:'关于 - 汐阡联集'},
    component: () => import('../views/about.vue')
  },
  {
    path:'/soraverse',
    meta:{title:'商店 - 魂月矩阵'},
    component: () => import('../views/SORAVERSE.vue')
  },
  {
    path:'/op',
    meta:{title:'汐阡网络控制台'},
    component:() => import('../views/op.vue')
  },
  {
    path:'/fs',
    meta:{title:'滑稽生存服务器'},
    component:() => import('../views/funnyserver.vue')
  },
  {
    path:'/fs/rules',
    meta:{title:'规则 - 滑稽生存服务器'},
    component:() => import('../views/funnyserverRules.vue')
  },
  {
    path:'/fs/notice',
    meta:{title:'公告 - 滑稽生存服务器'},
    component:() => import('../views/funnyserverNotice.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect:'/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {//beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  next()//执行进入路由，如果不写就不会进入目标页
})

router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  document.documentElement.scrollTop = 0;
  next();
});


export default router
